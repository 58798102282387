import { ElLoading, ElMessage, ElMessageBox } from 'element-plus';
import 'element-plus/es/components/loading/style/css'
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'

import { mixins } from "@/plugins/mixins";
export default {
  name: "rideCurrRecord",
  mixins: [mixins],
  data() {
    return {
      dialogVisible: false,
      refundDialogVisible: false,
      isAdd: false,
      refundModel: {
        reason: '',
        refundFee: 0
      },
      currentRecord: null,
      src: '',
      columns: [{
        type: 'expand',
        typeSlot: 'logExpand'
      }, {
        label: '机构',
        prop: 'orgName'
      }, {
        label: '用户',
        prop: 'customerName'
      }, {
        label: '电话',
        prop: 'phone'
      }, {
        label: '开始时间',
        prop: 'beginTime',
        width: '170px'
      }, {
        label: '已付费用',
        prop: 'paidFee'
      }, {
        label: '骑行里程',
        prop: 'mileage'
      }],
      topButtons: [],
      linkButtons: [],
      // 搜索字段
      searchItems: [{
        prop: 'phone',
        label: '手机号码'
      }, {
        prop: 'time',
        propItems: ['beginTime', 'endTime'],
        label: '时间范围',
        type: 'date',
        dateType: 'daterange',
        valueFormat: 'YYYY-MM-DD',
        defaultValue: [new Date().format('yyyy-MM-dd'), new Date().format('yyyy-MM-dd')],
        shortcuts: this.defaultDateShortcuts()
      }]
    };
  },
  methods: {
    inputInfo() {
      const loading = ElLoading.service();
      const search = this.$refs.tablePage.searchFormModel;
      this.$api.business.rideCurrRecord.exportInfo(search).then(res => {
        this.blobFile(res, '在停记录信息.xlsx', failData => {
          ElMessage.error(failData.message);
        }, () => {});
      }).finally(() => {
        loading.close();
      });
    },
    loadLog(item) {
      if (!item.id) {
        return;
      }
      this.$api.business.rideCurrRecord.getLogs({
        id: item.id,
        parkRecordTime: item.beginTime
      }).then(res => {
        if (res.code === 200) {
          item.logs = res.data;
        }
      });
      return '加载中';
    },
    out(row) {
      ElMessageBox.confirm('确认要离场该在停记录吗?', '提示', {
        type: 'warning'
      }).then(() => {
        const loading = ElLoading.service();
        this.$api.business.rideCurrRecord.recordOut(row.id).then(res => {
          if (res.code === 200) {
            ElMessage({
              message: res.message,
              type: 'success'
            });
            this.$refs.tablePage.fetch();
          } else {
            ElMessage.error(res.message);
          }
        }).finally(() => {
          loading.close();
        });
      });
    }
  }
};